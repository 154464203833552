<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Roles
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-btn color='primary' @click='showCreateDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New Role
        </v-btn>
      </v-flex>
      <v-flex sm6 xs12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <ViewRoles
              :roles='roles'
              :loading='loading'
              ref='cViewRoles'
            ></ViewRoles>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
import ViewRoles from '../../components/account/roles/RolesDisp';
export default {
  components: {
    ViewRoles
  },
  data() {
    return {
      roles: [],
      loading: false
    };
  },
  created() {
    this.api_getRoles();
  },
  methods: {
    showCreateDlg() {
      this.$refs.cViewRoles.createDlg();
    },
    async api_getRoles() {
      this.loading = true;
      this.roles = [];
      const res = await WebH.Get('/roles', 'roles');
      if (res.Success) this.roles = res.Data;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
