export const permissionMixin = {
  data() {
    return {
      permissionList: [
        { Id: 1, Name: "View Users" },
        { Id: 2, Name: "Edit Users" },
        { Id: 3, Name: "Add User" },
        { Id: 4, Name: "Remove User" },
        { Id: 5, Name: "View Settings" },
        { Id: 6, Name: "Edit Settings" },
        { Id: 7, Name: "Reset Settings" },
        { Id: 21, Name: "Vew Patients" },
        { Id: 22, Name: "Edit Patients" },
        { Id: 23, Name: "Add Patients" },
        { Id: 24, Name: "Remove Patients" },
        { Id: 25, Name: "Edit Appointments" },
        { Id: 26, Name: "Edit Answers" },
        { Id: 33, Name: "Add answers" },
        { Id: 27, Name: "Edit Drugs" },
        { Id: 28, Name: "Add Drugs" },
        { Id: 29, Name: "Delete Drugs" },
        { Id: 30, Name: "View Drugs" },
        { Id: 31, Name: "View Prescriptions" },
        { Id: 32, Name: "Add Prescriptions" },
        { Id: 34, Name: "View lab reports" },
        { Id: 35, Name: "Add lab reports" },
        { Id: 36, Name: "View SPT" },
        { Id: 37, Name: "Add SPT" },
        { Id: 38, Name: "Edit Examination" },
        { Id: 39, Name: "Add Examination" },
        { Id: 40, Name: "Edit Investigation" },
        { Id: 41, Name: "Add Investigation" },
        { Id: 42, Name: "Edit Diagnoses" },
        { Id: 43, Name: "Add Diagnoses" },
        { Id: 44, Name: "Edit questions settings" },
        { Id: 45, Name: "Add question settings" }
      ]
    };
  },
  methods: {
    getName(id) {
      const val = this.permissionList.find(x => x.Id === id);
      if (val) return val.Name;
      return null;
    },
    getPermissions(permissions) {
      let permits = [];
      for (let i = 0; i < permissions.length; i++) {
        const permit = this.permissionList.find(x => x.Id === permissions[i]);
        if (permit) permits.push(permit);
      }
      return permits;
    },
    getIdFromPermissions(permissions) {
      let permit = [];
      for (let i = 0; i < permissions.length; i++) {
        permit.push(permissions[i].Id);
      }
      return permit;
    }
  }
};
