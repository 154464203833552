<template>
  <div>
    <v-data-table
      :headers='headers'
      :items='roles'
      item-key='Id'
      :rows-per-page-items='rows_per_page'
      :loading='loading'
      class='elevation-1'
    >
      <template slot='items' slot-scope='props'>
        <tr>
          <td>{{ props.item.Name }}</td>
          <td style='text-align: center'>
            <button class='linkBtn' type='button' @click='viewDlg(props.item)'>
              View
            </button>
            <button class='linkBtn' type='button' @click='editDlg(props.item)'>
              Edit
            </button>
            <button
              class='linkBtn'
              type='button'
              @click='deleteDlg(props.item)'
            >
              Delete
            </button>
          </td>
        </tr>
      </template>
    </v-data-table>
    <RoleDlg
      :dlg-show='dlgShow'
      :dlg-type='dlgType'
      :item='selectedItem'
      @close='closeDlg'
    ></RoleDlg>
  </div>
</template>

<script>
import WebH from '../../../api/web';
import RoleDlg from './RoleDlg';
export default {
  components: {
    RoleDlg
  },
  props: ['roles', 'loading'],
  data() {
    return {
      rows_per_page: [25, 50],
      headers: [
        { text: 'Name', value: 'Name', sortable: true, width: '20%' },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          width: '20%',
          align: 'center'
        }
      ],
      dlgShow: false,
      dlgType: 0,
      selectedItem: null
    };
  },
  methods: {
    closeDlg() {
      this.dlgShow = false;
    },
    createDlg() {
      this.selectedItem = {
        Name: '',
        Description: '',
        Permissions: []
      };
      this.dlgType = 0;
      this.dlgShow = true;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      //this.selectedItem = item;
      this.dlgType = 2;
      this.dlgShow = true;
    },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    async deleteDlg(item) {
      const res = await this.$notifyDlg.show(
        'Do you really want delete this role?',
        'Delete role',
        'warning',
        true
      );
      if (res) {
        this.api_delete(item.Id);
      }
    },
    async api_delete(data) {
      const res = await WebH.Delete('/roles/' + data);
      if (res.Success) {
        this.$notify({
          type: 'info',
          text: 'Successfully deleted the role'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>
