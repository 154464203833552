<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>{{ title }} role</div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.trim='dlgModel.Name'
          :disabled='dlgType > 0'
          name='name'
          label='Name:'
          v-validate='{ required: true }'
          :error='!!errors.has("name")'
          :error-messages='errors.first("name")'
        >
        </v-text-field>
        <v-textarea
          :disabled='dlgType === 2'
          no-resize
          label='Description:'
          v-model.trim='dlgModel.Description'
          name='description'
          v-validate='{ required: true }'
          :error='!!errors.has("description")'
          :error-messages='errors.first("description")'
          rows='2'
        ></v-textarea>
        <v-list subheader class='permList'>
          <v-subheader>Permissions:</v-subheader>
          <v-list-tile v-for='item in permissions' :key='item.Name'>
            <v-list-tile-action>
              <v-checkbox v-model='item.Selected'></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content @click='item.Selected = !item.Selected'>
              <v-list-tile-title v-text='item.Name'></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-tile>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-tile>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          :disabled='dlgType === 2'
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../../api/web';
import { permissionMixin } from '../../../api/permissionMixin';
export default {
  mixins: [permissionMixin],
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    dlgType: {
      type: Number,
      default: 2
    },
    item: Object
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      permissions: [],
      dlgModel: {
        Name: '',
        Description: ''
      }
    };
  },
  computed: {
    title() {
      return this.dlgType === 0
        ? 'Create'
        : this.dlgType === 1
        ? 'Edit'
        : 'View';
    }
  },
  watch: {
    item: function() {
      this.permissions = [];
      for (let i = 0; i < this.permissionList.length; i++) {
        const found = this.item.Permissions.includes(this.permissionList[i].Id);
        this.permissions.push({
          Id: this.permissionList[i].Id,
          Name: this.permissionList[i].Name,
          Selected: found
        });
      }
      this.dlgModel = {
        Name: this.item.Name,
        Description: this.item.Description
      };
    }
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        Name: '',
        Description: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      const newItem = {
        Description: this.dlgModel.Description
      };
      const permits = [];
      for (let i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i].Selected) permits.push(this.permissions[i].Id);
      }
      newItem.Permissions = permits;
      if (this.dlgType === 0) {
        newItem.Name = this.dlgModel.Name;
        this.api_create(newItem);
      } else {
        newItem.Id = this.item.Id;
        this.api_update(newItem);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/roles', data);
      this.submit.working = false;
      if (res.Success) {
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      this.submit.working = true;
      const res = await WebH.Put('/roles/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped>
.permList {
  height: 200px;
  border: 1px solid lightgray;
  overflow: auto;
}
</style>
